import { NavItem } from './nav-item/nav-item';

export const navItems: NavItem[] = [
  {
    navCap: 'Home',
  },
  {
    displayName: 'Dashboard',
    iconName: 'aperture',
    route: '/dashboard',
  },
 
  {
    navCap: 'Quick Actions',
  },

  
  
  
  {
    displayName: 'Trip Operations',
    iconName: 'receipt-2',
    route: 'apps/operations',
    children: [
      {
        displayName: 'Add Task',
        iconName: 'point',
        route: 'apps/operations/add-task',
      },
      {
        displayName: 'Tasks',
        iconName: 'point',
        route: 'apps/operations/tasks',
      },
      {
        displayName: 'Schedule Trip',
        iconName: 'point',
        route: 'apps/operations/create-trip',
      },
      {
        displayName: 'Trips',
        iconName: 'point',
        route: 'apps/operations/trips',
      },
      {
        displayName: 'Fleet',
        iconName: 'point',
        route: 'apps/operations/fleet',
      },
      {
        displayName: 'Trip Issues',
        iconName: 'point',
        route: 'apps/operations/issues',
      },
      {
        displayName: 'Routes',
        iconName: 'point',
        route: 'apps/operations/routes',
      }
    ],
  },

  // {
  //   displayName: 'Transporters',
  //   iconName: 'truck-delivery',
  //   route: 'apps/transporters',
  //   children: [
  //     {
  //       displayName: 'Add Transporter',
  //       iconName: 'point',
  //       route: 'apps/transporters/add',
  //     },
  //     {
  //       displayName: 'Transpoters',
  //       iconName: 'point',
  //       route: 'apps/transporters/list',
  //     }
  //   ],
  // },

  {
    displayName: 'Prime Mover',
    iconName: 'tir',
    route: 'apps/vehicles',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/vehicles/add',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/vehicles/list',
      }
    ],
  },
  {
    displayName: 'Trailers',
    iconName: 'caravan',
    route: 'apps/trailers',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/trailers/add',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/trailers/list',
      }
    ],
  },
  {
    displayName: 'Expense',
    iconName: 'receipt-2',
    route: 'apps/expenses',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/expenses/add',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/expenses/list',
      }
    ],
  },
  
  
  {
    displayName: 'Products',
    iconName: 'stack-push',
    route: 'apps/products',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/products/add',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/products/list',
      }
    ],
  },
  

  {
    displayName: 'Workshop',
    iconName: 'car-crash',
    route: 'apps/workshops',
    children: [
      {
        displayName: 'Jobcard',
        iconName: 'point',
        route: 'apps/workshops/jobcard',
      },
      {
        displayName: 'Jobcards',
        iconName: 'point',
        route: 'apps/workshops/jobacards',
      }
    ],
  },
  
  {
    displayName: 'Customers',
    iconName: 'users-group',
    route: 'apps/customers',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/customers/add',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/customers/list',
      }
    ],
  },
  {
    displayName: 'Suppliers',
    iconName: 'users',
    route: 'apps/suppliers',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/suppliers/add',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/suppliers/list',
      }
    ],
  },

  {
    displayName: 'Documents',
    iconName: 'books',
    route: 'apps/documents',
    children: [
      // {
      //   displayName: 'Add Document',
      //   iconName: 'point',
      //   route: 'apps/suppliers/add',
      // },
      {
        displayName: 'Documents List',
        iconName: 'point',
        route: 'apps/documents/list',
      }
    ],
  },



  {
    displayName: 'Fuel Stations',
    iconName: 'gas-station',
    route: 'apps/stations',
    children: [
      {
        displayName: 'Add Station',
        iconName: 'point',
        route: 'apps/stations/add',
      },
      {
        displayName: 'Stations',
        iconName: 'point',
        route: 'apps/stations/list',
      }
    ],
  },

 
  
  
  {
    displayName: 'Orders',
    iconName: 'shopping-cart-copy',
    route: 'apps/orders',
    children: [
      {
        displayName: 'Fuel Order',
        iconName: 'point',
        route: 'apps/orders/fuel-orders',
      },
      {
        displayName: 'Purchase Order',
        iconName: 'point',
        route: 'apps/orders/purchase-order',
      },
      {
        displayName: 'Purchase Orders',
        iconName: 'point',
        route: 'apps/orders/purchase-orders',
      }
    ],
  },
 
  // {
  //   displayName: 'Invoice',
  //   iconName: 'file-invoice',
  //   route: 'apps/invoice',
  // },
 
  {
    displayName: 'Add Employee',
    iconName: 'user-plus',
    route: 'apps/add-employee',
  },

  {
    displayName: 'Employees',
    iconName: 'users',
    route: 'apps/employee',
  },

  // {
  //   displayName: 'Widgets',
  //   iconName: 'layout',
  //   route: 'widgets',
  //   children: [
  //     {
  //       displayName: 'Cards',
  //       iconName: 'point',
  //       route: 'widgets/cards',
  //     },
  //     {
  //       displayName: 'Banners',
  //       iconName: 'point',
  //       route: 'widgets/banners',
  //     },
  //     {
  //       displayName: 'Charts',
  //       iconName: 'point',
  //       route: 'widgets/charts',
  //     },
  //   ],
  // },


  {
    navCap: 'Settings',
  },


  
  {
    displayName: 'Company',
    iconName: 'home-2',
    route: 'apps/company',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/company/add-company',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/company/list',
      }
    ],
  },


  {
    displayName: 'Branchs',
    iconName: 'brand-stackshare',
    route: 'apps/branch',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/branch/add',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/branch/branch-list',
      }
    ],
  },
  {
    displayName: 'Categories',
    iconName: 'layout',
    route: 'widgets',
    children: [
      {
        displayName: 'List',
        iconName: 'point',
        route: 'categories/list',
      },
     
    ],
  },
 
  {
    displayName: 'Roles',
    iconName: 'lock-access',
    route: 'apps/roles',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/roles/add',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/roles/list',
      }
    ],
  },
 


  {
    displayName: 'Account Setting',
    iconName: 'user-circle',
    route: 'apps/users/account',
  },

  // {
  //   navCap: 'Forms',
  // },
  // {
  //   displayName: 'Form elements',
  //   iconName: 'apps',
  //   route: 'forms/forms-elements',
  //   children: [
  //     {
  //       displayName: 'Autocomplete',
  //       iconName: 'point',
  //       route: 'forms/forms-elements/autocomplete',
  //     },
  //     {
  //       displayName: 'Button',
  //       iconName: 'point',
  //       route: 'forms/forms-elements/button',
  //     },
  //     {
  //       displayName: 'Checkbox',
  //       iconName: 'point',
  //       route: 'forms/forms-elements/checkbox',
  //     },
  //     {
  //       displayName: 'Radio',
  //       iconName: 'point',
  //       route: 'forms/forms-elements/radio',
  //     },
  //     {
  //       displayName: 'Datepicker',
  //       iconName: 'point',
  //       route: 'forms/forms-elements/datepicker',
  //     },
  //   ],
  // },
  // {
  //   displayName: 'Form Layouts',
  //   iconName: 'file-description',
  //   route: '/forms/form-layouts',
  // },
  // {
  //   displayName: 'Form Horizontal',
  //   iconName: 'box-align-bottom',
  //   route: '/forms/form-horizontal',
  // },
  // {
  //   displayName: 'Form Vertical',
  //   iconName: 'box-align-left',
  //   route: '/forms/form-vertical',
  // },
  // {
  //   displayName: 'Form Wizard',
  //   iconName: 'files',
  //   route: '/forms/form-wizard',
  // },
  // {
  //   navCap: 'Tables',
  // },
  // {
  //   displayName: 'Tables',
  //   iconName: 'layout',
  //   route: 'tables',
  //   children: [
  //     {
  //       displayName: 'Basic Table',
  //       iconName: 'point',
  //       route: 'tables/basic-table',
  //     },
  //     {
  //       displayName: 'Dynamic Table',
  //       iconName: 'point',
  //       route: 'tables/dynamic-table',
  //     },
  //     {
  //       displayName: 'Expand Table',
  //       iconName: 'point',
  //       route: 'tables/expand-table',
  //     },
  //     {
  //       displayName: 'Filterable Table',
  //       iconName: 'point',
  //       route: 'tables/filterable-table',
  //     },
  //     {
  //       displayName: 'Footer Row Table',
  //       iconName: 'point',
  //       route: 'tables/footer-row-table',
  //     },
  //     {
  //       displayName: 'HTTP Table',
  //       iconName: 'point',
  //       route: 'tables/http-table',
  //     },
  //     {
  //       displayName: 'Mix Table',
  //       iconName: 'point',
  //       route: 'tables/mix-table',
  //     },
  //     {
  //       displayName: 'Multi Header Footer',
  //       iconName: 'point',
  //       route: 'tables/multi-header-footer-table',
  //     },
  //     {
  //       displayName: 'Pagination Table',
  //       iconName: 'point',
  //       route: 'tables/pagination-table',
  //     },
  //     {
  //       displayName: 'Row Context Table',
  //       iconName: 'point',
  //       route: 'tables/row-context-table',
  //     },
  //     {
  //       displayName: 'Selection Table',
  //       iconName: 'point',
  //       route: 'tables/selection-table',
  //     },
  //     {
  //       displayName: 'Sortable Table',
  //       iconName: 'point',
  //       route: 'tables/sortable-table',
  //     },
  //     {
  //       displayName: 'Sticky Column',
  //       iconName: 'point',
  //       route: 'tables/sticky-column-table',
  //     },
  //     {
  //       displayName: 'Sticky Header Footer',
  //       iconName: 'point',
  //       route: 'tables/sticky-header-footer-table',
  //     },
  //   ],
  // },
  // {
  //   displayName: 'Data table',
  //   iconName: 'border-outer',
  //   route: '/datatable/kichen-sink',
  // },
  // {
  //   navCap: 'Chart',
  // },
  // {
  //   displayName: 'Line',
  //   iconName: 'chart-line',
  //   route: '/charts/line',
  // },
  // {
  //   displayName: 'Gredient',
  //   iconName: 'chart-arcs',
  //   route: '/charts/gredient',
  // },
  // {
  //   displayName: 'Area',
  //   iconName: 'chart-area',
  //   route: '/charts/area',
  // },
  // {
  //   displayName: 'Candlestick',
  //   iconName: 'chart-candle',
  //   route: '/charts/candlestick',
  // },
  // {
  //   displayName: 'Column',
  //   iconName: 'chart-dots',
  //   route: '/charts/column',
  // },
  // {
  //   displayName: 'Doughnut & Pie',
  //   iconName: 'chart-donut-3',
  //   route: '/charts/doughnut-pie',
  // },
  // {
  //   displayName: 'Radialbar & Radar',
  //   iconName: 'chart-radar',
  //   route: '/charts/radial-radar',
  // },
  // {
  //   navCap: 'UI',
  // },
  // {
  //   displayName: 'Ui Components',
  //   iconName: 'box',
  //   route: 'ui-components',
  //   children: [
  //     {
  //       displayName: 'Badge',
  //       iconName: 'point',
  //       route: 'ui-components/badge',
  //     },
  //     {
  //       displayName: 'Expansion Panel',
  //       iconName: 'point',
  //       route: 'ui-components/expansion',
  //     },
  //     {
  //       displayName: 'Chips',
  //       iconName: 'point',
  //       route: 'ui-components/chips',
  //     },
  //     {
  //       displayName: 'Dialog',
  //       iconName: 'point',
  //       route: 'ui-components/dialog',
  //     },
  //     {
  //       displayName: 'Lists',
  //       iconName: 'point',
  //       route: 'ui-components/lists',
  //     },
  //     {
  //       displayName: 'Divider',
  //       iconName: 'point',
  //       route: 'ui-components/divider',
  //     },
  //     {
  //       displayName: 'Menu',
  //       iconName: 'point',
  //       route: 'ui-components/menu',
  //     },
  //     {
  //       displayName: 'Paginator',
  //       iconName: 'point',
  //       route: 'ui-components/paginator',
  //     },
  //     {
  //       displayName: 'Progress Bar',
  //       iconName: 'point',
  //       route: 'ui-components/progress',
  //     },
  //     {
  //       displayName: 'Progress Spinner',
  //       iconName: 'point',
  //       route: 'ui-components/progress-spinner',
  //     },
  //     {
  //       displayName: 'Ripples',
  //       iconName: 'point',
  //       route: 'ui-components/ripples',
  //     },
  //     {
  //       displayName: 'Slide Toggle',
  //       iconName: 'point',
  //       route: 'ui-components/slide-toggle',
  //     },
  //     {
  //       displayName: 'Slider',
  //       iconName: 'point',
  //       route: 'ui-components/slider',
  //     },
  //     {
  //       displayName: 'Snackbar',
  //       iconName: 'point',
  //       route: 'ui-components/snackbar',
  //     },
  //     {
  //       displayName: 'Tabs',
  //       iconName: 'point',
  //       route: 'ui-components/tabs',
  //     },
  //     {
  //       displayName: 'Toolbar',
  //       iconName: 'point',
  //       route: 'ui-components/toolbar',
  //     },
  //     {
  //       displayName: 'Tooltips',
  //       iconName: 'point',
  //       route: 'ui-components/tooltips',
  //     },
  //   ],
  // },
 
];
